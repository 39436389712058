import {
  AssessmentButton,
  Bullets,
  Pagination,
  TemplateSearch,
  showToast,
} from "ui-components";
import "./CreateAssessmentReview.scss";
import { useTranslation } from "react-i18next";
import "../CreatedAssessmentList/CreatedAssessmentList.scss";
import { useNavigate, useParams } from "react-router-dom";
import { getAssessmentDetails } from "../../../service/Invite/Invite";
import { useEffect, useRef, useState } from "react";
import {
  companyGetAssessmentApplicantCount,
  companyGetAssessmentApplicantList,
} from "../../../service/CreatedAssessmentListAndReview/CreatedAssessmentListAndReview";
import ContentList from "./ContentList";
import SideContent from "./SideContent";
import Filter from "./Filter";
import DataNotAvailable from "../../../utils/DataNotFound/DataNotAvailable";
import {
  reopenDeletedAssessment,
  updateAssessmentStatus,
} from "../../../service/Scope/Scope";
import {
  copyToClipboard,
  manageDuplicateSkills,
} from "../../../utils/Common/Constant";
import useDebounce from "../../../utils/Hooks/useDebounce";
import BackArrow from "../../../utils/Images/BackArrow";
import CopyVector from "../../../utils/Images/CopyVector";
import FilterButton from "../../../components/FilterButton/FilterButton";
import DownArrowIcon from "../../../utils/Images/DownArrowIcon";
import UpArrowIcon from "../../../utils/Images/UpArrowIcon";
import CustomModal from "../../../components/CustomModal/CustomModal";
function CreateAssessmentReview() {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const { assessmentIdParam } = useParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCountValue, setFilterCountValue] = useState<any>(null);
  const [isClosePopup, setIsClosePopup] = useState(() => {
    const closeAssessPopup = new URLSearchParams(location.search).get("ca");
    return closeAssessPopup ? JSON.parse(closeAssessPopup) : false;
  });
  const urlRef = useRef(null);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params: any = new URLSearchParams(location.search);
  const searchParameter = url.searchParams.get("search");
  const [filters, setFilters] = useState(() => {
    return {
      all: params.get("all") ? JSON.parse(params.get("all")) : true,
      others: params.get("others") ? JSON.parse(params.get("others")) : false,
      submitted: params.get("submitted")
        ? JSON.parse(params.get("submitted"))
        : false,
      selected: params.get("selected")
        ? JSON.parse(params.get("selected"))
        : false,
      reviewed: params.get("reviewed")
        ? JSON.parse(params.get("reviewed"))
        : false,
      date: params.get("date") ? JSON.parse(params.get("date")) : true,
      score: params.get("score") ? JSON.parse(params.get("score")) : true,
      notified: params.get("notified")
        ? JSON.parse(params.get("notified"))
        : false,
      notNotified: params.get("notNotified")
        ? JSON.parse(params.get("notNotified"))
        : false,
    };
  });

  const [assessmentDetail, setAssessmentDetail] = useState<any>(null);
  const [search, setSearch] = useState<string>(() => {
    const searchText = new URLSearchParams(location.search).get("search");
    return searchText ? searchText : "";
  });
  const searchUserAssessment = useDebounce(search.trim(), 700);
  const [assessmentApplicantList, setAssessmentApplicantList] = useState([]);
  const [isListData, setIsListData] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isNotified, setIsNotified] = useState(0);
  const [isYesPress, setIsYesPress] = useState(false);
  useEffect(() => {
    if (filterCountValue?.submitted > 0) {
      setFilters((prev: any) => ({
        ...prev,
        all: params.get("all") ? JSON.parse(params.get("all")) : false,
        submitted: params.get("submitted")
          ? JSON.parse(params.get("submitted"))
          : true,
      }));
    }
  }, [filterCountValue]);
  useEffect(() => {
    getAssessDetails();
  }, []);
  // this will render only when we search
  useEffect(() => {
    assessmentApplicantCount(assessmentIdParam ?? "", searchUserAssessment);
  }, [searchUserAssessment, isNotified]);
  useEffect(() => {
    const data = {
      assessmentId: assessmentIdParam,
      score: JSON.parse(params.get("score")) ? "desc" : "asc",
      date: JSON.parse(params.get("date")) ? "desc" : "asc",
      search: searchUserAssessment,
      filterOptions: {
        all: filters?.all ? "All" : "",
        others: filters?.others ? true : "", //["New", "Initiated", "Deferred", "Blocked", "Barred"]
        submitted: filters?.submitted ? "Submitted" : "",
        reviewed: filters?.reviewed ? "Reviewed" : "",
        selected: filters?.selected ? "Shortlisted" : "",
        notified: filters?.notified ? true : "",
        notNotified: filters?.notNotified ? false : "",
      },
      currentPage: currentPage,
      pageSize,
    };
    const { date, score, ...updatedFilters } = filters;
    const areAllFiltersFalse = () => {
      return Object.values(updatedFilters).every((filter) => filter === false);
    };
    const allFiltersFalse = areAllFiltersFalse();
    if (allFiltersFalse) {
      setFilters((prev: any) => ({ ...prev, all: true, date, score }));
    }
    if (searchParameter === null && filterCountValue?.all === 0) {
      setIsListData(false);
    }
    getAssessmentApplicantList(data);
    window.scrollTo(0, 0);
  }, [
    searchUserAssessment,
    filters?.score,
    filters?.date,
    filters?.all,
    filters?.others,
    filters?.submitted,
    filters?.reviewed,
    filters?.selected,
    filters?.notified,
    filters?.notNotified,
    currentPage,
    isNotified,
    filterCountValue,
  ]);
  const handleFilter = (value: any) => {
    setCurrentPage(1);

    setFilters((prevFilters: any) => {
      // Handle "score" or "date" filter toggling separately
      let newFilters;
      if (value === "score" || value === "date") {
        newFilters = { ...prevFilters, [value]: !prevFilters[value] };
      }
      // If "all" is clicked, reset all other filters except "score" and "date"
      else if (value === "all") {
        newFilters = {
          ...Object.fromEntries(
            Object.keys(prevFilters).map((key) =>
              key === "all" || key === "score" || key === "date"
                ? [key, prevFilters[key]]
                : [key, key === "all"],
            ),
          ),
          all: true,
        };
      }
      // Handle "Others" filter which includes "initiated", "notInitiated", and "deferred"
      else if (value === "others") {
        const isActive = !prevFilters.others;
        newFilters = {
          ...prevFilters,
          others: isActive,
          all: false, // Ensure "all" is deselected
        };
      }
      // If any other filter is clicked, unselect "notified" and "notNotified"
      else {
        newFilters = {
          ...prevFilters,
          [value]: !prevFilters[value],
        };
        // Ensure "all" is set to false if any individual filter is selected
        if (newFilters.all) {
          newFilters.all = false;
        }
      }
      // Update the URL with new filters
      const params = new URLSearchParams(window.location.search);
      Object.keys(newFilters).forEach((key) => {
        params.set(key, JSON.stringify(newFilters[key]));
      });
      // Update the URL without reloading the page
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`,
      );
      return newFilters;
    });
  };
  // get assessment deatails
  async function getAssessDetails() {
    const assessDetails = await getAssessmentDetails(assessmentIdParam ?? "");
    if (assessDetails?.status === 200) {
      setAssessmentDetail(assessDetails?.data);
    } else {
      showToast(assessDetails?.data?.customMessage, "error");
      if (assessDetails?.status === 409) {
        navigate(`/${currentLanguage}/404-not-found`);
      }
    }
  }
  // get company AssessmentApplicantList
  async function getAssessmentApplicantList(data: any) {
    const applicantList: any = await companyGetAssessmentApplicantList(data);
    if (applicantList?.data?.status === 200) {
      // if (filterCountValue?.all > 0) {
      //   setIsListData(true);
      // }
      setAssessmentApplicantList(applicantList?.data?.data?.data);
      setTotalCount(applicantList?.data?.data?.totalCount);
    } else {
      showToast(applicantList?.data?.customMessage, "error");
      if (applicantList?.status === 409) {
        navigate(`/${currentLanguage}/404-not-found`);
      }
    }
  }
  //  to get company Assessment Applicant Count
  async function assessmentApplicantCount(
    assessmentId: string,
    search?: string,
  ) {
    const applicantCount = await companyGetAssessmentApplicantCount(
      assessmentId,
      search,
    );
    if (applicantCount?.data?.status === 200) {
      setFilterCountValue(applicantCount?.data?.data);
    } else {
      // showToast(applicantCount?.data?.customMessage, "error");
    }
  }
  //open/close closeAssessment popup function
  const handlePopUpOpenCloseStatus = (popupStatus: string) => {
    url.searchParams.set("ca", popupStatus);
    history.replaceState({}, "", url.toString());
    const isClosePopup: any = new URLSearchParams(location.search).get("ca");
    setIsClosePopup(JSON.parse(isClosePopup));
  };
  // function for mark as completed
  const handleAssessmentStatus = async (status: string) => {
    setIsYesPress(true);
    const markData = {
      assessmentId: assessmentIdParam,
      status: status,
    };
    let markRes;
    if (status === "closed" || status === "Closed") {
      markRes = await updateAssessmentStatus(markData);
    } else {
      markRes = await reopenDeletedAssessment(markData);
    }
    if (markRes?.data?.status === 200) {
      setIsNotified(Math.random() * 10);
      showToast(markRes?.data?.customMessage, "success");
      handlePopUpOpenCloseStatus("false");
      setIsYesPress(false);
      getAssessDetails();
    } else {
      showToast(markRes?.data?.customMessage, "error");
      handlePopUpOpenCloseStatus("false");
      setIsYesPress(false);
    }
  };
  return (
    <div className="AssessmentReviewSection">
      <div className="backArrow" onClick={() => window.history.back()}>
        <BackArrow />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="skillsNameSec">
              <div className="d-flex">
                <h3>{assessmentDetail?.title}</h3>
                {assessmentDetail?.slugUrl && (
                  <div
                    ref={urlRef}
                    className="listCopyIcon"
                    onClick={() =>
                      copyToClipboard(
                        urlRef,
                        assessmentDetail?.slugUrl,
                        t("INVITE.COPY_SUCCESS"),
                      )
                    }
                    title={t("GENERAL_PUBLIC_URL")}
                  >
                    <CopyVector color="var(--primary-green)" />
                  </div>
                )}
              </div>
              {assessmentDetail?.status === "published" ||
                assessmentDetail?.status === "Published" ? (
                <div className="d-flex gap-3 wrapBtns">
                  <AssessmentButton
                    onClick={() => {
                      handlePopUpOpenCloseStatus("true");
                    }}
                    className="lightBtn"
                  >
                    {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.CLOSE_ASSESS")}
                  </AssessmentButton>
                  {filterCountValue?.all > 0 && (
                    <>
                      {/* <AssessmentButton
                        onClick={() =>
                          navigate(
                            `/${currentLanguage}/assessment/invite/${assessmentIdParam}`,
                          )
                        }
                        className="lightBtn"
                      >
                        {t("GENERAL.RECOMMENDATIONS")}
                      </AssessmentButton> */}
                      <AssessmentButton
                        onClick={() =>
                          navigate(
                            `/${currentLanguage}/assessment/invite/${assessmentIdParam}`,
                          )
                        }
                        className="lightBtn"
                      >
                        {t("GENERAL.INVITE")}
                      </AssessmentButton>
                    </>
                  )}
                </div>
              ) : assessmentApplicantList?.length ? (
                <div className="assessmentCloseStatus">
                  <span>{t("GENERAL.ASSESSMENT_CLOSED")}</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="skillsNames">
              <div className="d-flex align-items-center">
                <div className="link_wrap">
                  <p className="acc_style04">
                    {assessmentDetail?.isPublic ? "Public" : "Private"}
                  </p>
                </div>
                <span>{assessmentDetail?.assessmentType}</span>
              </div>
              <div className="bulletSec">
                {(
                  manageDuplicateSkills(assessmentDetail?.assessmentSkills) ||
                  []
                ).map((item: any, index: number) => {
                  return <Bullets key={index} skillName={item?.skillName} />;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row skillsSection">
          <SideContent
            assessmentDetail={assessmentDetail}
            totalSelected={filterCountValue?.selected}
          />
          <div className="col-sm-12 col-md-9 col-lg-10">
            {isListData ? (
              <>
                <div className="AssessmentListSearchSec  ">
                  <div className="col-9">
                    <TemplateSearch
                      onChange={(e) => {
                        url.searchParams.set("search", e);
                        history.replaceState({}, "", url.toString());
                        const searchApplicant: any = new URLSearchParams(
                          location.search,
                        ).get("search");
                        setSearch(searchApplicant);
                        setCurrentPage(1);
                      }}
                      value={search}
                      placeholder={t("GENERAL.SEARCH_APPLICATION")}
                    />
                  </div>
                  <div className="col-3 scoreDateFilters">
                    <div className="breakSpace" />
                    <div className="arrowBtnSec">
                      <p>{t("GENERAL.SCORE")}</p>
                      <FilterButton
                        handleFilter={handleFilter}
                        name="score"
                        isActive={filters?.score}
                      >
                        {filters?.score ? (
                          <DownArrowIcon isDisable={filters?.score} />
                        ) : (
                          <UpArrowIcon isDisable={filters?.score} />
                        )}
                      </FilterButton>
                    </div>
                    <div className="arrowBtnSec">
                      <p>{t("GENERAL.DATE")}</p>
                      <FilterButton
                        handleFilter={handleFilter}
                        name="date"
                        isActive={filters?.date}
                      >
                        {filters?.date ? (
                          <DownArrowIcon isDisable={filters?.date} />
                        ) : (
                          <UpArrowIcon isDisable={filters?.date} />
                        )}
                      </FilterButton>
                    </div>
                  </div>
                </div>
                <Filter
                  filters={filters}
                  handleFilter={handleFilter}
                  filterCountValue={filterCountValue}
                />
                {totalCount > 0 && (
                  <p className="totalContent">
                    {t("INVITE.TOTAL_APPLICANT")} : {totalCount}
                  </p>
                )}
                {assessmentApplicantList?.length > 0 ? (
                  <div className="contentList">
                    <ContentList
                      assessmentApplicantList={assessmentApplicantList}
                      assessmentDetail={assessmentDetail}
                      setIsNotified={setIsNotified}
                    />
                  </div>
                ) : (
                  <DataNotAvailable />
                )}
              </>
            ) : (
              <div className="emptyContentListMsg">
                {assessmentDetail?.status === "Published" ? (
                  <>
                    <span>{t("GENERAL.NO_APPLICANT")}</span>
                    <br />
                    <span>{t("GENERAL.INVITE_CANDIDATE")}</span>
                  </>
                ) : (
                  <span>{t("GENERAL.ASSESSMENT_CLOSED")}</span>
                )}
                {assessmentDetail?.status === "Published" && (
                  <div className="emptyContentBtn">
                    {/* <AssessmentButton
                      onClick={() =>
                        navigate(
                          `/${currentLanguage}/assessment/invite/${assessmentIdParam}`,
                        )
                      }
                      className="lightBtn btnWidth"
                    >
                      {t("GENERAL.RECOMMENDATIONS")}
                    </AssessmentButton> */}
                    <AssessmentButton
                      onClick={() =>
                        navigate(
                          `/${currentLanguage}/assessment/invite/${assessmentIdParam}`,
                        )
                      }
                      className="lightBtn btnWidth"
                    >
                      {t("GENERAL.INVITE")}
                    </AssessmentButton>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
              ofLabel={t("GENERAL.OF")}
              resultLabel={t("GENERAL.RESULTS")}
              setPageSize={setPageSize}
            />
          </div>
        </div>
        {isClosePopup && (
          <CustomModal
            show={isClosePopup}
            isHeaderVisible={true}
            isFooterVisible={false}
            instructionHeadTitle={t(
              "CREATED_ASSESSMENT_LIST_AND_REVIEW.CLOSE_ASSESS",
            )}
            className="closeAssessPopup"
          >
            <div className="markAsCloseModal">
              <div className="closeModalText">
                <span className="">
                  {t("GENERAL.CLOSE_ASSESS_TEXT1")} (
                  {filterCountValue?.selected}){" "}
                  {t("GENERAL.CLOSE_ASSESS_TEXT2")} (
                  {filterCountValue?.reviewed}){" "}
                  {t("GENERAL.CLOSE_ASSESS_TEXT3")}
                </span>
              </div>
              <div className="closeModalBtn">
                <AssessmentButton
                  onClick={() => handleAssessmentStatus("Closed")}
                  className="lightBtn"
                  disabled={isYesPress}
                >
                  {t("GENERAL.YES")}
                </AssessmentButton>
                <AssessmentButton
                  onClick={() => {
                    handlePopUpOpenCloseStatus("false");
                  }}
                  className="lightBtn"
                  disabled={isYesPress}
                >
                  {t("GENERAL.NO")}
                </AssessmentButton>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
}

export default CreateAssessmentReview;
