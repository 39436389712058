import { AssessmentButton, InputField } from "ui-components";
import CompanyOnboardWrapper from "../../../components/CompanyOnboard/CompanyOnboardWrapper";
import "./LoginSignup.scss";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { loginUserData } from "../../../utils/Common/Constant";
import handleApplicantLogin from "./LoginApiCall";
const Login = () => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
  });
  const onSignupCLick = () => {
    navigate(`/${currentLanguage}/company-signup`);
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.DONOT_HAVE_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_UP")}
      signInUpClick={onSignupCLick}
      isGoogleSignBtn={true}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          //call login function and pass parameters
          handleApplicantLogin(
            value?.email,
            value?.password,
            "Custom",
            "",
            "",
            resetForm,
            setSubmitting,
            navigate,
            currentLanguage,
          );
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <div className="companyLogin">
              <Form>
                <div className="userSec">
                  <div className="userContent">
                    <div className="heading-div">
                      <h4 className="userHeadingApp">{t("welcome")}</h4>
                    </div>
                    <Field name="email">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.REGISTER_EMAIL")} *`}
                          onChange={(newValue: any) =>
                            setFieldValue("email", newValue.trim())
                          }
                          value={values.email}
                          additionalProps={{ className: "textInput" }}
                        />
                      )}
                    </Field>
                    {errors?.email && touched?.email ? (
                      <div className="errorMessage">{errors?.email}</div>
                    ) : null}

                    <Field name="password">
                      {() => (
                        <div className="passwordSec">
                          <InputField
                            placeholder={`${t("GENERAL.PASSWORD")} *`}
                            onChange={(newValue) =>
                              setFieldValue("password", newValue)
                            }
                            value={values.password}
                            additionalProps={{
                              className: "textInput passwordInput",
                            }}
                            type="password"
                          />
                        </div>
                      )}
                    </Field>
                    {errors?.password && touched?.password ? (
                      <div className="errorMessage">{errors?.password}</div>
                    ) : null}

                    <AssessmentButton
                      className="submitBtn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("GENERAL.LOADING")
                        : t("GENERAL.SIGN_IN")}
                    </AssessmentButton>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </CompanyOnboardWrapper>
  );
};

export default Login;
