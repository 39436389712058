import { TemplateSearch } from "ui-components";
import { useTranslation } from "react-i18next";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
const TransactionHistory = () => {
  const { t } = useTranslation();
  const columns: any = [
    { Header: t("GENERAL.EVENT"), accessor: "event" },
    { Header: t("GENERAL.EVENT_ID"), accessor: "eventId" },
    { Header: t("GENERAL.DATE"), accessor: "date" },
    { Header: t("GENERAL.CREDITS"), accessor: "credits" },
    { Header: t("GENERAL.TYPE"), accessor: "type" },
    { Header: t("GENERAL.BALANCE"), accessor: "balance" },
    { Header: t("GENERAL.REMARKS"), accessor: "remark" },
  ];
  const data = [
    {
      event: "Buy Assessment",
      eventId: "TVRAF23DB3C4",
      date: "1 Oct 2024, Tue 12:47 PM",
      credits: "5000",
      type: "Credit",
      balance: "5004",
      remark: "-",
    },
    {
      event: "Account Recharge",
      eventId: "TVRAF23DB3C4",
      date: "1 Oct 2024, Tue 12:47 PM",
      credits: "5000",
      type: "Debit",
      balance: "5004",
      remark: "-",
    },
    {
      event: "Buy Assessment",
      eventId: "TVRAF23DB3C4",
      date: "1 Oct 2024, Tue 12:47 PM",
      credits: "5000",
      type: "Credit",
      balance: "5004",
      remark: "-",
    },
    {
      event: "Buy Assessment",
      eventId: "TVRAF23DB3C4",
      date: "1 Oct 2024, Tue 12:47 PM",
      credits: "5000",
      type: "Debit",
      balance: "5004",
      remark: "-",
    },
    {
      event: "Account Recharge",
      eventId: "TVRAF23DB3C4",
      date: "1 Oct 2024, Tue 12:47 PM",
      credits: "5000",
      type: "Debit",
      balance: "5004",
      remark: "-",
    },
  ];
  return (
    <div className="account-container accountsRightDescription">
      <div className="search-bar">
        <TemplateSearch
          placeholder={t("GENERAL.SEARCH_EVENT")}
          iconName="searchList"
          onChange={() => {}}
        />
      </div>
      <div className="accounts-table">
        <TransactionTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default TransactionHistory;
