import { useTranslation } from "react-i18next";
import { AssessmentButton } from "ui-components";

const AccountDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="accountsRightDescription">
      <div className="topRow">
        <div className="lastRechargeSec ">
          <div className="textRow">
            <span className="rightContentHeading ">
              {t("GENERAL.LAST_RECHARGE")}
            </span>
            <span className="textInfo">1 Oct 2024, Tue 12:47 PM</span>
          </div>
          <AssessmentButton className="rechargeBtn">
            {t("GENERAL.RECHARGE")}
          </AssessmentButton>
        </div>
        <div className="textRow alignEnd">
          <span className="rightContentHeading">
            {t("GENERAL.ACCOUNT_BALANCE")}
          </span>
          <span className="textInfo bolderBalance">50,000</span>
        </div>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">
          {t("GENERAL.TOTAL_ASSESSMENTS")}
        </span>
        <span className="textInfo">20</span>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">{t("GENERAL.CURRENT_PLAN")}</span>
        <span className="textInfo">Large Business Assessment Plan</span>
      </div>
    </div>
  );
};

export default AccountDetails;
