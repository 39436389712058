import { AssessmentButton, InputField, showToast } from "ui-components";
import CompanyOnboardWrapper from "../../../components/CompanyOnboard/CompanyOnboardWrapper";
import "./LoginSignup.scss";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resendOtp, verifyGetOtp } from "../../../service/Scope/Scope";
import { formatTime, loginUserData } from "../../../utils/Common/Constant";
import { useEffect, useState } from "react";

const VerifyOtpScreen = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { email } = state || {};
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [resendBtnDisable, setResendBtnDisable] = useState(false);
  useEffect(() => {
    const savedTime = sessionStorage.getItem("leftTime");
    if (savedTime !== null) {
      const timeRemaining = Number(savedTime);
      if (timeRemaining > 0) {
        setTimer(timeRemaining);
        setIsTimerActive(true);
      } else {
        setTimer(0);
        setIsTimerActive(false);
      }
    } else {
      sessionStorage.setItem("leftTime", "60");
    }

    const interval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime > 1) {
          sessionStorage.setItem("leftTime", (prevTime - 1).toString());
          return prevTime - 1;
        } else {
          clearInterval(interval);
          setIsTimerActive(false);
          sessionStorage.setItem("leftTime", "0");
          setResendBtnDisable(false);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerActive]);

  const loginUserSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]+$/, t("ERROR.INVALID_OTP"))
      .required(t("ERROR.OTP_REQUIRED")),
  });

  const handleVerifyOtp = async (
    value: any,
    resetForm: any,
    setSubmitting: any,
  ) => {
    const data = {
      email: email,
      otp: Number(value.otp),
    };
    try {
      setSubmitting(true);
      const getCompanyOtp = await verifyGetOtp(data);
      if (getCompanyOtp.status === 200) {
        showToast(getCompanyOtp.customMessage, "success");
        resetForm();
        localStorage.setItem("companyUserId", getCompanyOtp.data.companyUserId);
        localStorage.setItem("email", email);
        sessionStorage.setItem("leftTime", "60");
        navigate(`/${currentLanguage}/create-password`, { replace: true });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      resetForm();
    } finally {
      setSubmitting(false);
    }
  };

  const onSignInClick = () => {
    navigate("/");
  };

  const resendOtpApi = async () => {
    setTimer(60);
    sessionStorage.setItem("leftTime", "60");
    setResendBtnDisable(true);
    try {
      const res = await resendOtp(email);
      showToast(res.customMessage, "success");
      setIsTimerActive(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.ALREADY_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_IN")}
      signInUpClick={onSignInClick}
      isGoogleSignBtn={true}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleVerifyOtp(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => (
          <div className="companyLogin">
            <Form>
              <div className="userSec">
                <div className="userContent veryfiOtpSec">
                  <div className="heading-div">
                    <h4 className="userHeadingApp">{t("GENERAL.ENTEROTP")}</h4>
                    <div className="confirm-otp-text">
                      <span>
                        {t("GENERAL.ENTER_OTP")}{" "}
                        <span>
                          <b>{email}</b>
                        </span>
                      </span>
                    </div>
                  </div>
                  <Field name="otp">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.PASS_CODE")}`}
                        onChange={(newValue: any) => {
                          setFieldValue("otp", newValue.trim());
                        }}
                        value={values?.otp}
                        additionalProps={{ className: "textInput" }}
                      />
                    )}
                  </Field>
                  {errors?.otp && touched?.otp ? (
                    <div className="errorMessage enterOtpError">
                      {errors?.otp}
                    </div>
                  ) : null}
                  <div className="resend-otp-sec">
                    {!isTimerActive ? (
                      <div
                        className={`resend-otp-btn ${resendBtnDisable ? "disable-resend-btn" : ""}`}
                        onClick={!isTimerActive ? resendOtpApi : () => {}}
                      >
                        <span>{t("GENERAL.RESEND_OTP")}</span>
                      </div>
                    ) : (
                      <span>
                        &nbsp;
                        {formatTime(Number(timer))}
                      </span>
                    )}
                  </div>
                  <AssessmentButton
                    className="submitBtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? t("GENERAL.LOADING")
                      : t("GENERAL.VERIFY_OTP")}
                  </AssessmentButton>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </CompanyOnboardWrapper>
  );
};

export default VerifyOtpScreen;
