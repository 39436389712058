import { MultiSelector, RangeSelector, Tooltip } from "ui-components";
import "./ExperienceLeftSide.scss";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { Field, useFormikContext } from "formik";

export interface IExperienceLeftSideProps {
  errors?: any;
  touched?: any;
  experienceMinValue: any;
  setExperienceMinValue: any;
  experienceMaxValue: any;
  setExperienceMaxValue: any;
  noOfPositions: any;
  setNoOfPositions: any;
  cutOff: any;
  setCutOff: any;
  maximumResponse: any;
  setMaximumResponse: any;
}

const ExperienceLeftSide: React.FunctionComponent<IExperienceLeftSideProps> = ({
  errors = null,
  touched = null,
  experienceMinValue,
  setExperienceMinValue,
  experienceMaxValue,
  setExperienceMaxValue,
  noOfPositions,
  setNoOfPositions,
  cutOff,
  setCutOff,
  maximumResponse,
  setMaximumResponse,
}) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext || {};
  const handleValue = (value: number, setFn: any, name: string) => {
    setFn(value);
    setSessionStorageItem(name, value);
  };

  useEffect(() => {
    // get the set value from localstorage
    setFieldValue(
      "positionsCount",
      getSessionStorageItem("noOfPositions") || "",
    );
  }, []);

  return (
    <div className="rangeTextSec">
      <h4 className="detailsRole">{t("GENERAL.DETAILS_ROLE")}</h4>
      <div>
        <MultiSelector
          id="one"
          label={`${t("CREATE_ASSESSMENT.EXPERIENCE_YEAR")}*`}
          minLabel="0"
          maxLabel="60"
          min={0}
          max={60}
          value={experienceMinValue}
          setValue={(value) => {
            handleValue(value, setExperienceMinValue, "minimumExperience");
          }}
          maxValue={experienceMaxValue}
          setMaxValue={(value) => {
            handleValue(value, setExperienceMaxValue, "maximumExperience");
          }}
        />
      </div>
      <div className="posSec">
        <Field name="positionsCount">
          {() => (
            <RangeSelector
              id="two6547bdgtrb4564"
              label={`${t("CREATE_ASSESSMENT.POSITION")}*`}
              minLabel={"01"}
              maxLabel={500}
              step={1}
              min={1}
              max={500}
              value={noOfPositions}
              setValue={(value) => {
                handleValue(value, setNoOfPositions, "noOfPositions");
                setFieldValue("positionsCount", value);
              }}
            />
          )}
        </Field>
        {errors?.positionsCount && touched?.positionsCount ? (
          <div className="errorMessage">{errors?.positionsCount}</div>
        ) : null}
      </div>
      <div>
        <RangeSelector
          id="three5635ghbhdvc7tr32"
          label={`${t("CREATE_ASSESSMENT.CUTOFF_PERCENTAGE")}*`}
          minLabel={0}
          step={1}
          maxLabel={100}
          value={cutOff}
          setValue={(value) => {
            handleValue(value, setCutOff, "cutOff");
          }}
        />
      </div>
      <div className="maxResponseDiv">
        <div className="iIcon">
          <Tooltip
            classname="tooltipOverCss"
            text={t("CREATE_ASSESSMENT.MAXIMUM_RESPONSES_TOOLTIP")}
          />
        </div>
        <RangeSelector
          id="djcnsfv85jnvsrjv"
          label={`${t("CREATE_ASSESSMENT.MAXIMUM_RESPONSES")}*`}
          minLabel={0}
          step={1}
          maxLabel={100}
          value={maximumResponse}
          setValue={(value) => {
            handleValue(value, setMaximumResponse, "maximumResponse");
          }}
        />
      </div>
    </div>
  );
};

export default ExperienceLeftSide;
