export interface TableColumn<T> {
  Header: string;
  accessor: keyof T;
}
export interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
}
const TransactionTable = <T extends Record<string, any>>({
  columns,
  data,
}: TableProps<T>) => {
  return (
    // Table start
    <div className="table-responsive">
      <table>
        <div className="row mb-3 table-container">
          {/* table header */}
          <div className="tabel-header">
            {columns.map((item, index) => (
              <div key={index} className="col">
                <span>{item?.Header}</span>
              </div>
            ))}
          </div>
          {/* table rows */}
          <div className="container event-row">
            {data.map((item, index) => (
              <>
                <div key={index} className="row mb-3 event-item">
                  <div className="col">
                    <span>{item.event}</span>
                  </div>
                  <div className="col">
                    <span>{item.eventId}</span>
                  </div>
                  <div className="col">
                    <span>{item.date}</span>
                  </div>
                  <div className="col">
                    <span>{item.credits}</span>
                  </div>
                  <div className="col">
                    <span>{item.type}</span>
                  </div>
                  <div className="col status-column">
                    <span>{item.balance}</span>
                  </div>
                  <div className="col">
                    <span>{item.remark}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </table>
    </div>
  );
};

export default TransactionTable;
