import { useState } from "react";
import AiStamp from "../../utils/Images/AiStamp";
import CustomModal from "../CustomModal/CustomModal";
import "./AiConfidenceStamp.scss";
interface props {
  width?: string;
  height?: string;
  score?: any;
}
const AiConfidenceStamp = ({ width, height, score }: props) => {
  const [show,] = useState(false)
  const handlePopupOpen = (e: any) => {
    console.log(e);

    // e.stopPropagation();
    // setShow(true)
  }
  const handleClosePopup = () => {
    // setShow(false)
  }
  return (
    <>
      <div className="aiStamp" onClick={(e: any) => handlePopupOpen(e)}>
        <AiStamp height={height} width={width} />
        <div className="aiConfidenceMark">
          <span>{score}%</span>
        </div>
      </div>
      <CustomModal
        show={show}
        isCrossBtn={true}
        isHeaderVisible={false}
        handleClose={handleClosePopup}
        className="supportReportPopup"
        size="lg"
        fullscreen="md-down"
      >
        <div className="supportReportContent">
          <div className="supportHeader">
            <h2 className="supportTitle">
              { }
            </h2>
          </div>

        </div>
      </CustomModal>
    </>
  );
};

export default AiConfidenceStamp;
